.setting {
  .submenuWrapper {
    .css-46bh2p-MuiCardContent-root {
      padding: 0px;
    }
    cursor: default;
    // max-height: 70vh;
    height: 100%;
    background-color: $white;
    box-shadow: 0px 13px 10px 0px rgba(18, 20, 51, 0.04);
    border: 1px solid rgba(200, 203, 208, 0.5);
    border-radius: 16px !important;

    .navItem {
      padding: 16px 19px 16px 15px;
      border-bottom: 2px solid $card-bg-color;
      .nav {
        padding: 8px 13px;
        font: normal 400 16px/24px $font-family-base;
      }
      .selectedNav {
        padding: 19px 32px 19px 32px;
        background-color: $card-bg-color;
        @include border-radius(8px);
        color: $primaryColorPurple2;
      }
      .rightArrow {
        height: 24px;
        width: 24px;
        min-width: 24px;
        background-color: $colorNeutralGrey03;
        @include border-radius(50%);
      }
    }
  }
  .accountDetailsWrapper {
    .accountDetails {
      .accountDetailContent {
        min-height: calc(100vh - 392px);
        overflow: auto;
        @include commonScrollBar;
      }
      .divider {
        height: 1.6px;
        background-color: $card-bg-color !important;
      }

      .boxPadding {
        padding: 26px 17px;
        .description {
          font: normal 400 14px/20px $font-family-base;
        }
        .highlight-color {
          color: $primaryColorPurple;
        }
      }
      .accountDetailFooter {
        @extend .cardFooter;
      }
    }
  }
  .ad-title {
    font: normal 600 24px/24px $font-family-base;
    padding: 19px 17px;
  }
  .mfaDetails {
    .mfaContent {
      min-height: calc(100vh - 302px);
      overflow: auto;
      @include commonScrollBar;
    }
  }
  .passwordWrapper {
    .passwordContent {
      width: 100%;
      padding: 10px 16px;
      .password {
        margin: 0px 0px 30px 0px;
      }
      .passwordMeter {
        width: 40%;
        background-color: #f5f7fd;
        @include border-radius(16px);
      }
    }
    .passwordFooter {
      @extend .cardFooter;
    }
  }
}
