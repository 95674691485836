//need to be refactor css

.tenants-max-height {
  height: calc(100vh - 403px);
  min-height: calc(100vh - 403px);
  .MuiDataGrid-virtualScroller {
    height: calc(100vh - 403px);
    max-height: calc(100vh - 403px);
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    @include commonScrollBar;
  }
}

.tenant-menu-item {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1.25rem !important;
  padding-right: 2.05rem !important;
}

.tenants-revisier-max-height {
  height: calc(100vh - 395px);
  min-height: calc(100vh - 395px);
  div.MuiDataGrid-virtualScroller {
    overflow-y: scroll !important;
    @include commonScrollBar;
    @media (min-width: 1280px) {
      height: calc(100vh - 395px);
      overflow-x: hidden !important;
    }
  }
}
.bg-color-search {
  &.MuiInputBase-root {
    background-color: $white !important;
  }
}
.bg-more-info-modal {
  background-color: $loaderbgColor;
  max-width: 36.8125rem !important;
  @include border-radius(8px);
}

.close-icon-modal {
  &.MuiButtonBase-root {
    color: $closeIconPopupColor;
  }
}

.chip-status {
  &.MuiChip-root {
    border-radius: 0.375rem;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 22px;
    height: 22px;
    letter-spacing: -0.25px;
    .MuiChip-label {
      padding: 0px 10px;
    }
  }
}

.company-button-modal {
  &.MuiChip-root {
    border: $companyBtnborderColor;
    background-color: $multiselectCheckbox;
    color: $bg-surface-color;
    width: fit-content;
    border-radius: 8px;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 8px 20px;
  }
}

.modal-avtar {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}

.tableBorder {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  border-style: hidden;
  box-shadow: 1px 1px 1px 1px #d2c3d6;
}

//more info modal
.modalInfotextTitle {
  color: $colorNeutralGrey07;
  .compCount {
    margin-right: 4px;
  }
}

//headerSearchBox
.headerSearchBox {
  min-width: 300px;
  height: 44px;
  @include border-radius(8px);
  box-shadow: 0px 4px 12px 0px rgba(13, 10, 44, 0.06) !important;
  .icon-search {
    color: rgba(18, 20, 51, 1);
    font-size: 24px;
  }
  .MuiInputBase-root {
    &.MuiInputBase-colorPrimary {
      height: 44px;
    }
  }
}
