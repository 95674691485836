.textAreaWithReadMore {
  .readMoreText {
    border: none;
    padding: 7px 10px;
    background-color: $card-bg-color;
    min-height: 100px;
    border-radius: 10px;
    cursor: pointer;
  }
}
.regulationObligation {
  max-height: 400px;
  @extend .taskScroll;
}
.regulationCalendarPadding {
  // pt: '15px', pl: '32px', pr: '38px', pb: '16px'
  padding-top: 15px;
  padding-left: 32px;
  padding-right: 38px;
  padding-bottom: 16px;
}
.sourceInput {
  &.MuiTextField-root {
    margin-top: 3px;
  }
}
.hideBackgroundColor {
  .enforcementSection {
    background-color: $white !important ;
  }
}
