.filterGroup {
  .filterButton {
    @extend .cursorPointer;
    padding: 9px 24px;
    background-color: $white;
    border: 1px solid transparent;
    @include border-radius(8px);
    box-shadow: 0px 4px 12px 0px rgba(13, 10, 44, 0.06);
    .btnText {
      font: normal 400 14px/18px $font-family-base;
      color: rgba(18, 20, 51, 0.7);
    }
    [class^='icon-'] {
      font-size: 24px;
      color: rgba(18, 20, 51, 1);
    }
    &.filterButtonActive {
      border: 1px solid #3f4159;
      .btnText {
        color: $primaryTextColor;
      }
    }
  }
  &.filterButtonDisabled {
    pointer-events: none;
    opacity: 0.7;
    .icon-dropdown {
      color: $calendarTextDisabled;
    }
    .btnText {
      color: rgba(18, 20, 51, 0.7);
    }
  }
}

//filterPopover
.filterPopover {
  .MuiMenu-paper {
    @include border-radius(8px);
    border: 1px solid #e9e9f8 !important;
    background: $white;
    box-shadow: 0px 12.52155px 10.01724px 0px rgba(25, 42, 75, 0.04);
    .MuiMenu-list {
      min-width: 280px;
      padding: 0px;
      .filterList {
        padding: 6px 20px;
        height: 220px;
        min-height: 220px;
        overflow: auto;
        @include commonScrollBar;
        .MuiListItem-root {
          padding: 13px 0px;
          margin-bottom: 4px;
          label {
            span:nth-child(2) {
              @extend .text-ellipsis;
              max-width: 200px;
            }
          }
        }
        .MuiTypography-body1 {
          padding-left: 10px;
          font: normal 400 14px/18px $font-family-base;
          color: $primaryTextColor;
        }
      }
    }
  }
}

//selectedFilter
.selectedFilter {
  .filterItemSelected {
    font: normal 500 14px/18px $font-family-base;
    color: $primaryColorPurple;
    letter-spacing: 0.14px;
    border-radius: 8px;
    border: 1px solid #d2c3d6;
    background: #e9e9f8;
    padding: 5px 20px;
  }
  .resetBtn {
    letter-spacing: 0.28px;
    line-height: 20px;
    .MuiTypography-body2 {
      color: $primaryColorPurple;
    }
  }
}
