.regulations {
  .current {
    .regulationListScroll {
      @extend .commonScrollBar;
      height: calc(100vh - 346px);
    }
    .regulationsSummary {
      .summaryCard {
        @media only screen and (min-width: 1280px) {
          height: calc(100vh - 440px);
        }

        @extend .commonListScroll;

        .summaryDetails {
          .summaryHeader {
            .MuiTypography-h6 {
              letter-spacing: 0.32px;
            }
            .summaryEdit {
              div {
                width: 28px;
                height: 28px;
                @include border-radius(4px);
                @extend .cursorPointer;
                background: #f1f3f8;
                [class*='icon-'] {
                  color: rgba(78, 39, 136, 1);
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
      .actionPlanOverview {
        // width: 375px;
        .actionPlanOverviewContent {
          height: 270px;
          background-color: $card-bg-color;
          margin-bottom: 32px;
          @include border-radius(7px);
          .graphWraper {
            height: 150px;
            margin: 18px;
            .radialGraphProgress {
              .radialCircle {
                .apexcharts-canvas {
                  margin-left: -25px;
                }
                .taskCompletedCount {
                  font: normal 700 14px/20px $font-family-base;
                  letter-spacing: 0.28px;
                  @extend .p-absolute;
                  top: 40%;
                  left: 33%;
                }
              }
              .statusCountText {
                font: normal 400 12px/18px $font-family-base;
              }
              .statusText {
                width: 130px;
                height: 130px;
              }
              .graphTitle {
                font: normal 400 11px/16px $font-family-base;
                margin-top: -19px;
              }
            }
          }
          .progressBarWrapper {
            .overviewLabel {
              padding: 14px 20px 0px 20px;
              .progressBarLabel {
                font: normal 400 0.68rem/1rem $font-family-base;
                span:first-child {
                  color: rgba(63, 65, 89, 0.8);
                }
              }
              .progressBarDate {
                font: normal 700 0.75rem/1rem $font-family-base;
              }
            }
            .progressBar {
              padding: 24px 20px 18px 20px;
              .css-lnu19z-MuiLinearProgress-bar1 {
                background: linear-gradient(
                  to right,
                  rgba(30, 156, 35, 1),
                  rgba(221, 234, 119, 1),
                  rgba(253, 33, 48, 1)
                );
              }
            }
          }
        }
      }
      .disabledScreen {
        background: url('../../assets/images/blurredBack.png');
        margin-top: 20px;
        margin-bottom: 50px;
        background-color: $card-bg-color;
        width: 100%;
        height: 207px;
        flex-shrink: 0;
        .viewBtn {
          font: normal 600 14px/16px $font-family-base;
        }
      }
    }
    .summaryLastChanges {
      .MuiTypography-body2.mt-3 {
        color: $colorNeutralGrey07;
      }
    }
    .actionPlanSection {
      .actionPlanCreate {
        @include border-radius(16px);
        border: 1px solid $colorNeutralGrey03;
        background: $white;
        filter: drop-shadow(0px 4px 12px rgba(13, 10, 44, 0.07));
      }
      .createActionBtn {
        .MuiButton-startIcon {
          margin-right: 4px !important;
        }
      }
    }
  }
}

.regulationSource {
  .imgBox {
    width: 36px;
    height: 36px;
    @include border-radius(16px);
    filter: drop-shadow(0px 4px 12px rgba(13, 10, 44, 0.13));
    background-color: $white;
    .icon-source-link {
      font-size: 24px;
      color: rgba(63, 65, 89, 1);
    }
  }
  .MuiTypography-body2 {
    opacity: 0.7;
  }
  .regulationSourceLink {
    a {
      font: normal 500 14px/18px $font-family-base;
      color: $primaryColorPurple;
      letter-spacing: 0.14px;
    }
  }
}

//regulationPolicySection
.regulationPolicies {
  .enforcement {
    @extend .p-relative;
    &:first-child {
      .enforcementSection {
        background: transparent !important;
        padding-left: 0px !important;
      }
    }

    &:after {
      content: '';
      height: 1px;
      background-color: #e9e9f8;
      width: 22%;
      right: -34px;
      top: 35px;
      @extend .p-absolute;
    }
    .enforcementLabel {
      font-weight: 500 !important;
      color: $primaryTextColor !important;
    }
    .enforcementValue {
      font: normal 400 14px/18px $font-family-base !important;
      color: $colorNeutralGrey07 !important;
    }
  }
  .gapAnalysis {
    .enforcement {
      &:after {
        content: none !important;
      }
    }
  }
}
.regulationPolicySection {
  .regulationPolicyView {
    .MuiGrid-item {
      &:first-child,
      &:nth-child(2) {
        border-right: 1px solid #e9e9f8;
        height: calc(100vh - 182px);
        padding-left: 0px !important;
      }
    }
  }
  .regulationPolicyCardBody {
    padding-bottom: 0px !important;
    overflow: hidden !important;
    height: calc(100vh - 190px);
    .policyEditBtn {
      width: 32px;
      height: 32px;
      @include border-radius(8px);
      border: 1px solid #e9e9f8;
      @extend .cursorPointer;
      background: #f1f3f8;
      [class*='icon-'] {
        color: rgba(78, 39, 136, 1);
        font-size: 20px;
      }
    }
    .editControlOption {
      height: calc(100vh - 300px);
      .submitButtonSection {
        width: 100%;
        box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.06),
          0px 0px 4px 0px rgba(0, 0, 0, 0.04);
        @include flex-basic-center;
        background-color: #fff;
        padding: 10px;
        position: absolute;
        bottom: 0px;
      }
      textarea {
        @extend .commonScrollBar;
      }
    }
    .policyData {
      @extend .commonScrollBar;
      height: calc(100vh - 302px);
    }
    .lineHeigt-32 {
      line-height: 32px;
    }
  }
}

//support text
.supportedText {
  font: normal 500 12px/16px $font-family-base;
  color: $colorNeutralGrey07;
}

//emptyView
.emptyView {
  border-radius: 16px;
  border: 1px solid var(--color-surface-shade-03, $colorNeutralGrey03);
  background: $white;
  height: calc(100vh - 344px);
}

//enforcementSection
.enforcement {
  &:first-child {
    .enforcementSection {
      background: #e8faff;
    }
  }
  @media (min-width: 1460px) {
    &:nth-child(2) {
      position: relative;
      .d-flex.enforcementSection {
        &::after {
          position: absolute;
          content: none;
          width: 1.6px;
          height: 40px;
          right: -4px;
          background-color: rgba(233, 233, 248, 1);
        }
      }
    }
  }

  .enforcementSection {
    padding: 12px 24px;
    @include border-radius(8px);
    border: 0px solid #6f43b1;

    .iconBox {
      height: 36px;
      width: 36px;
      min-width: 36px;
      @include border-radius(50%);
      background-color: $white;
      filter: drop-shadow(0px 4px 12px rgba(13, 10, 44, 0.13));
    }
    .enforcementLabel {
      font: normal 400 14px/18px $font-family-base;
      color: $colorNeutralGrey07;
    }
    .enforcementValue {
      color: $primaryTextColor;
    }
  }
}

//regulationLatestChanges modal
.regulationLatestChanges {
  @include border-radius(8px);
  background: #f1f3f8;
}

//common class
.iconArrowBack {
  width: 32px;
  min-width: 32px;
  height: 32px;
  @include border-radius(50%);
  background: #31125f;
}

.cardFooter {
  padding: 7px;
  border-radius: 0px 0px 20px 20px;
  background: $white;
  box-shadow: 0px -2px 2.21381px 0px rgba(108, 73, 172, 0.02),
    0px -6.65px 5.32008px 0px rgba(113, 113, 113, 0.03),
    0px -20px 18px 0px rgba(184, 184, 184, 0.05);
}

//actionPlanView
.actionPlanView {
  .actionCardSection {
    .summaryCard {
      padding: 0px;
      // height: calc(100vh - 400px);
      @extend .commonListScroll;
      margin-right: 4px;
      .tablestyle.MuiCard-root {
        border: none;
        .taskListTable {
          overflow-y: scroll;
          .MuiDataGrid-overlayWrapper {
            top: 10px;
            height: 20px;
          }
          .MuiDataGrid-virtualScroller {
            padding: 10px;
            height: 100px;
          }
          .MuiDataGrid-virtualScrollerContent {
            // height: calc(100vh - 600px) !important;
            @extend .commonListScroll;
            .MuiDataGrid-cell {
              &.taskName {
                .task {
                  white-space: initial !important;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
                @media (min-width: 1460px) {
                  width: 560px;
                }
              }
            }
          }
          .MuiDataGrid-columnHeadersInner {
            div[role='row'] {
              .MuiDataGrid-columnHeader {
                .MuiDataGrid-columnHeaderDraggableContainer {
                  z-index: 9999;
                }
                &:first-child {
                  .MuiDataGrid-columnHeaderTitleContainerContent {
                    padding-left: 12px;
                  }
                }
              }
            }
          }

          .MuiDataGrid-row {
            .MuiDataGrid-cellCheckbox {
              padding-left: 12px;
            }
          }
        }
      }
      .paginationWrapper {
        padding: 0 32px;
      }
      .taskListControlBtns {
        @extend .p-absolute;
        right: 32px;
        top: 14px;
        z-index: 1;
        .selectHeight {
          height: 38px;

          .MuiInputBase-root {
            min-width: 160px;
            height: 38px;
            border: 1px solid;
            border-color: $selectBorderColor;
            p {
              opacity: 1 !important;
              color: $primaryTextColor !important;
              font: normal 600 14px/16px $font-family-base;
            }
          }

          .MuiSvgIcon-root,
          .MuiSelect-icon {
            top: 5px;
          }
        }
      }
    }
    .enforcement:nth-child(2) {
      @extend .p-relative;
      .enforcementSection {
        margin-left: 18px;
        &:after {
          left: 23px !important;
        }
      }
    }
    .summaryCollapseBtn {
      @extend .p-absolute;
      width: 32px;
      height: 32px;
      top: 0px;
      @include border-radius(8px);
      background: #f1f3f8;
      right: 0px;
      [class*='icon-'] {
        color: rgba(49, 18, 95, 1);
        font-size: 20px;
      }
    }
    .actionCardFooter {
      @extend .cardFooter;
    }
  }
  .customWidth100 {
    width: calc(100% - 80px);
  }
}

//taskPlanAdd/View/Edit
.taskPlan {
  .taskCardBody {
    height: calc(100vh - 429px);
    @extend .taskScroll;
    .textFields {
      &.multilineInput {
        .MuiTextField-root {
          .MuiInputBase-root {
            height: 60px !important;
            padding: 0px 10px 0px 20px !important;
            overflow: auto !important;
            .MuiInputBase-input.MuiOutlinedInput-input {
              height: auto !important;
              overflow: auto !important;
              @include commonScrollBar;
            }
          }
        }
      }
    }
    .contentScroll {
      height: calc(100vh - 429px);
      @extend .commonListScroll;
    }
  }
  .borderRight {
    border-right: 1px solid $colorNeutralGrey03;
  }
  .taskCardFooter {
    @extend .cardFooter;
    &.taskCardActionHidden {
      visibility: hidden;
    }
  }
}

//todo
.hidden {
  display: none;
}

.visible {
  display: block;
}

.viewTabs {
  .customToggleBtn {
    &.MuiToggleButtonGroup-root {
      .MuiToggleButton-root {
        padding: 10px 40px;
        font: normal 500 14px/18px $font-family-base;
        transition: none;
      }
    }
  }
}
