.MuiPaper-root {
  &.MuiCard-root {
    @include border-radius(16px);
    box-shadow: 0px 13px 10px 0px $card-box-shadow;
    border: 1px solid $card-box-border-color;
    .divider {
      height: 1px;
      background-color: #e9e9f8;
    }
    .MuiCardHeader-root {
      .MuiCardHeader-content {
        .MuiCardHeader-title {
          font: normal 600 28px/40px $font-family-base;
          color: $primaryTextColor;
        }
        .MuiCardHeader-subheader {
          font: italic 400 12px/16px $font-family-base;
          color: $primaryTextColor;
          .icon-info {
            color: rgba(180, 181, 192, 1);
            font-size: 16px;
          }
        }
      }
    }
  }
}
