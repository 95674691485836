.tourComponent {
  .MuiPaper-root .MuiDialog-paper {
    background-color: transparent !important ;
  }
}
.backgroundOverlay {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 1000;
  overflow: 'hidden';
  background-color: rgba(0, 0, 0, 0.9);
  color: $white;
  .overlayElement {
    position: fixed;
    width: 80vw;
    bottom: 5%;
    .content {
      background-color: $white;
      @include border-radius(8px);
      .overlayButton {
        border: 2px solid white;
        display: inline-block;
      }
      .overlayFont {
        color: $primaryTextColor;
        padding: 21px 30px 22px 31px;
        font: 500 normal 16px/20px $font-family-base;
      }
    }
    .mydoc {
      margin-right: 5px;
      margin-left: -3px;
      padding: 12px 16px;
      .goToMyDocs {
        box-shadow: 0 0 10px $buttonShadowColor,
          inset 0 0 18px $buttonShadowColor;
        color: $white;
        font: 500 normal 16px/24px $font-family-base;
        text-wrap: nowrap;
        border: 1px solid $tourGuideBtnBorderColor;
        :hover {
          background-color: $tourGuideBtnBorderColor;
        }
      }
    }
  }
}
