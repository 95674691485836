/** Used to define container behavior: width, position: fixed etc... **/

.Toastify__toast-container {
  width: 29.125rem !important;

  .Toastify__toast-icon {
    margin-right: 12px;
  }

  .Toastify__toast {
    @include border-radius(8px);

    padding: 1.25rem;
  }
}
