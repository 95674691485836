.customLinearProgress {
  .MuiLinearProgress-root {
    height: 7px;
    border-radius: 30px;
    background: #e9e9f8;
  }
}

//silder progress indicator
.progressBar {
  .MuiSlider-root {
    pointer-events: none !important;
    .MuiSlider-rail {
      border-radius: 30px;
      height: 5px;
      opacity: 1;
      background-color: #e5e5ef;
    }
    .MuiSlider-thumb {
      background-color: $white;
      height: 20px;
      width: 20px;
      min-width: 20px;
      border: 6px solid $colorAlertRed03;
    }
    .MuiSlider-track {
      height: 5px;
      border: none;
      background: linear-gradient(
        90deg,
        #1e9c23 0%,
        #ddea77 55.94%,
        #fd2130 111.88%
      );
    }
  }
}
