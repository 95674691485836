.gapHeader {
  border-bottom: 2px solid rgba(233, 233, 248, 1);
  .summaryCollapse {
    @include border-radius(4px);
  }
  .iconHeight {
    font-size: 20px;
  }
}
.gapOverviewScroll {
  max-height: calc(100vh - 550px);
  overflow-y: scroll;
  @extend .commonListScroll;
}
.gapActionPlanFiltersWrapper {
  .title {
    font: normal 600 24px/24px $font-family-base;
  }
}

.gapActionTable {
  @extend .commonListScroll;
  .gapAssessmentTable {
    border-bottom-left-radius: 0px !important;
  }
  .tablestyle {
    &.MuiCard-root {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border: 0px !important;
    }
  }

  .MuiCardContent-root {
    padding: 0px 0px 6px 0px;
  }
}
.gapTaskScroll {
  @extend .taskScroll;
  height: calc(100vh - 300px);
}
.GapTaskEditHeader {
  border-bottom: 1.6px solid $chipStatusBorderColor;
}
.roundedTag {
  word-wrap: none;
  display: -webkit-box;
}
.inputDisabledBoxBg {
  background-color: $loaderbgColor;
  color: $colorNeutralGrey07;
  cursor: not-allowed;
}
.normalInputBg {
  background-color: $accordianBgColor;
}
.inputReadonlyAndDownload {
  .inputBox {
    min-height: 50px;
    border: 1px solid $inputReadOnlyBackground;
    @include border-radius(8px);
    .ellipseText {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .inputReadonlyExpandIcon {
    padding: 4px;
    border-radius: 4px;
    font-size: 18px;
  }
}
.modalBg {
  @extend .normalInputBg;
  @include border-radius(8px);
  border-radius: 8px !important;
  overflow-y: scroll;
  @include commonScrollBar;
}
.bottomActionsShadow {
  padding-top: 7px;
  background: #fff;
  box-shadow: 0px -20px 18px 0px rgba(184, 184, 184, 0.02),
    0px -6.65px 5.32px 0px rgba(113, 113, 113, 0.01),
    0px -2px 2.214px 0px rgba(108, 73, 172, 0.01);
}

.gaps-task-max-height {
  height: calc(100vh - 503px);
  min-height: calc(100vh - 503px);
  .MuiDataGrid-virtualScroller {
    height: calc(100vh - 503px);
    max-height: calc(100vh - 503px);
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    @include commonScrollBar;
  }
}
