.m-width-Userbox {
  min-width: 250px;
}

.check-box-mui-select {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 4px;
  background: $multiselectCheckbox;
}
