.large {
  display: inline-block;
}
.small {
  display: none;
}
.SidebarWrapper {
  position: relative;
  z-index: 7;
  height: 100%;
  padding-bottom: 240px;
  .MuiListItem-root {
    .MuiButton-root {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.screenSize {
  width: '100vw';
  height: '100vh';
  overflow-x: 'hidden';
}
