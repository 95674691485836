.chartContainer {
  &.graphBorder {
    background-size: cover;
    background-repeat: no-repeat;
    height: 272px;
    width: 272px;
    position: relative;
    left: -15px;
  }
  .apexcharts-canvas {
    position: absolute;
    top: 6px;
    left: -25px;
  }

  .agencyText {
    position: absolute;
  }
  .chartInnerBoxShadow {
    background-size: cover;
    background-repeat: no-repeat;
    height: 104px;
    width: 104px;
    position: absolute;
    left: 30.5%;
    top: 29%;
    z-index: 1;
  }
}

//graphLegend

.graphLegend {
  overflow: auto;
  max-height: 256px;
  height: 256px;
  @include commonScrollBar;
  .indicaters {
    width: 22px;
    height: 8px;
    @include border-radius(8px);
  }
  .seriesItems {
    color: #263238;
    font: normal 600 12px/16px $font-family-base;
  }
}
